// import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import { useState, useEffect } from 'react';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
 
   const closeMenuOnMobile = () => {
     if (window.innerWidth <= 1150) {
       setShowMenu(false);
     }
   };

   useEffect(() => {
    window.addEventListener('resize', closeMenuOnMobile);

    return () => {
      window.removeEventListener('resize', closeMenuOnMobile);
    };
  }, []);

  return (
    <header className="header">
    <nav className="nav container">

      <NavLink 
        to="/" 
        className="nav__logo"
      >
        MINDWISE GLOBAL
      </NavLink>

      <div
        className={`nav__menu ${showMenu ? "show-menu" : ""}`}
        id="nav-menu"
      >
        <ul className="nav__list">

          <li className="nav__item">
            <NavLink 
              to="/" 
              className="nav__link"  
              onClick={closeMenuOnMobile}
            >
              HOME
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink 
              to="/news" 
              className="nav__link"  
              onClick={closeMenuOnMobile}
            >
              NEWS
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to="/partners"
              className="nav__link"
              onClick={closeMenuOnMobile}
            >
              PARTNERS
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to="/projects"
              className="nav__link"
              onClick={closeMenuOnMobile}
            >
              PROJECTS
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to="/services"
              className="nav__link"
              onClick={closeMenuOnMobile}
            >
              SERVICES
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to="/ai"
              className="nav__link"
              onClick={closeMenuOnMobile}
            >
              Ai products
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink 
              to="/contact" 
              className="nav__link"
              onClick={closeMenuOnMobile}
            >
              CONTACT US
            </NavLink>
          </li>
          
        </ul>
        
        <div 
          className="nav__close" 
          id="nav-close" 
          onClick={toggleMenu}
          >
          <IoClose />
        </div>
      </div>

      <div 
        className="nav__toggle" 
        id="nav-toggle" 
        onClick={toggleMenu}
        >
        <IoMenu />
      </div>
    </nav>
  </header>
  );
};

export default Navbar;
