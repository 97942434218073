import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Componets/Navbar/Navbar';
import Home from './Componets/Home/Home';
import News from './Componets/News/News';
import Partners from './Componets/Partneres/Partners';
import Projects from './Componets/Projects/Projects';
import Services from './Componets/Services/Services';
import Contact from  './Componets/Contact/contact';
import AI from './Componets/AIproducts/Ai';
function App() {
  return (
    <Router>
      <Navbar />
      <main className='main-content'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ai" element={<AI />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
