// import React from 'react'; 
import { gsap } from 'gsap';
import './Projects.css';
import png1 from './picProj/slider.png'
import { motion } from "framer-motion";
import { FiMousePointer } from "react-icons/fi";
import React, { useRef, useState, useEffect } from "react";

const Projects = () =>{
  ;(function(name, factory) {
    if (typeof window === 'object') {
      window[name] = factory()
    }
  })('Ribbons', function() {
    var _w = window,
      _b = document.body,
      _d = document.documentElement
 
    var random = function() {
      if (arguments.length === 1) {
        if (Array.isArray(arguments[0])) {
          var index = Math.round(random(0, arguments[0].length - 1))
          return arguments[0][index]
        }
        return random(0, arguments[0]) 
      } else if (arguments.length === 2) {

        return Math.random() * (arguments[1] - arguments[0]) + arguments[0]
      } else if (arguments.length === 4) {
  
        var array = [arguments[0], arguments[1], arguments[2], arguments[3]]
        return array[Math.floor(Math.random() * array.length)]
      }
      return 0
    }
  
    var screenInfo = function(e) {
      var width = Math.max(
          0,
          _w.innerWidth || _d.clientWidth || _b.clientWidth || 0
        ),
        height = Math.max(
          0,
          _w.innerHeight || _d.clientHeight || _b.clientHeight || 0
        ),
        scrollx =
          Math.max(0, _w.pageXOffset || _d.scrollLeft || _b.scrollLeft || 0) -
          (_d.clientLeft || 0),
        scrolly =
          Math.max(0, _w.pageYOffset || _d.scrollTop || _b.scrollTop || 0) -
          (_d.clientTop || 0)
  
      return {
        width: width,
        height: height,
        ratio: width / height,
        centerx: width / 2,
        centery: height / 2,
        scrollx: scrollx,
        scrolly: scrolly
      }
    }
  
    var mouseInfo = function(e) {
      var screen = screenInfo(e),
        mousex = e ? Math.max(0, e.pageX || e.clientX || 0) : 0,
        mousey = e ? Math.max(0, e.pageY || e.clientY || 0) : 0
  
      return {
        mousex: mousex,
        mousey: mousey,
        centerx: mousex - screen.width / 2,
        centery: mousey - screen.height / 2
      }
    }
  
    var Point = function(x, y) {
      this.x = 0
      this.y = 0
      this.set(x, y)
    }
    Point.prototype = {
      constructor: Point,
  
      set: function(x, y) {
        this.x = x || 0
        this.y = y || 0
      },
      copy: function(point) {
        this.x = point.x || 0
        this.y = point.y || 0
        return this
      },
      multiply: function(x, y) {
        this.x *= x || 1
        this.y *= y || 1
        return this
      },
      divide: function(x, y) {
        this.x /= x || 1
        this.y /= y || 1
        return this
      },
      add: function(x, y) {
        this.x += x || 0
        this.y += y || 0
        return this
      },
      subtract: function(x, y) {
        this.x -= x || 0
        this.y -= y || 0
        return this
      },
      clampX: function(min, max) {
        this.x = Math.max(min, Math.min(this.x, max))
        return this
      },
      clampY: function(min, max) {
        this.y = Math.max(min, Math.min(this.y, max))
        return this
      },
      flipX: function() {
        this.x *= -1
        return this
      },
      flipY: function() {
        this.y *= -1
        return this
      }
    }

    var Factory = function(options) {
      this._canvas = null
      this._context = null
      this._sto = null
      this._width = 0
      this._height = 0
      this._scroll = 0
      this._ribbons = []
      this._options = {
        colorSaturation: '80%',
        colorBrightness: '60%',
        colorAlpha: 0.65,
        colorCycleSpeed: 6,
        verticalPosition: 'center',
        horizontalSpeed: 150,
        ribbonCount: 3,
        strokeSize: 0,
        parallaxAmount: -0.5,
        animateSections: true
      }
      this._onDraw = this._onDraw.bind(this)
      this._onResize = this._onResize.bind(this)
      this._onScroll = this._onScroll.bind(this)
      this.setOptions(options)
      this.init()
    }
  
    Factory.prototype = {
      constructor: Factory,
  

      setOptions: function(options) {
        if (typeof options === 'object') {
          for (var key in options) {
            if (options.hasOwnProperty(key)) {
              this._options[key] = options[key]
            }
          }
        }
      },
  
      init: function() {
        try {
          this._canvas = document.createElement('canvas')
          this._canvas.style['display'] = 'block'
          this._canvas.style['position'] = 'fixed'
          this._canvas.style['margin'] = '0'
          this._canvas.style['padding'] = '0'
          this._canvas.style['border'] = '0'
          this._canvas.style['outline'] = '0'
          this._canvas.style['left'] = '0'
          this._canvas.style['top'] = '0'
          this._canvas.style['width'] = '100%'
          this._canvas.style['height'] = '100%'
          this._canvas.style['z-index'] = '-1'
          this._onResize()
  
          this._context = this._canvas.getContext('2d')
          this._context.clearRect(0, 0, this._width, this._height)
          this._context.globalAlpha = this._options.colorAlpha
  
          window.addEventListener('resize', this._onResize)
          window.addEventListener('scroll', this._onScroll)
          document.body.appendChild(this._canvas)
        } catch (e) {
          console.warn('Canvas Context Error: ' + e.toString())
          return
        }
        this._onDraw()
      },
  
      addRibbon: function() {
        var dir = Math.round(random(1, 9)) > 5 ? 'right' : 'left',
          stop = 1000,
          hide = 200,
          min = 0 - hide,
          max = this._width + hide,
          movex = 0,
          movey = 0,
          startx = dir === 'right' ? min : max,
          starty = Math.round(random(0, this._height))
  
        if (/^(top|min)$/i.test(this._options.verticalPosition)) {
          starty = 0 + hide
        } else if (/^(middle|center)$/i.test(this._options.verticalPosition)) {
          starty = this._height / 2
        } else if (/^(bottom|max)$/i.test(this._options.verticalPosition)) {
          starty = this._height - hide
        }
  
        var ribbon = [],
          point1 = new Point(startx, starty),
          point2 = new Point(startx, starty),
          point3 = null,
          color = Math.round(random(35, 35, 40, 40)),
          delay = 0
  
        while (true) {
          if (stop <= 0) break
          stop--
  
          movex = Math.round(
            (Math.random() * 1 - 0.2) * this._options.horizontalSpeed
          )
          movey = Math.round((Math.random() * 1 - 0.5) * (this._height * 0.25))
  
          point3 = new Point()
          point3.copy(point2)
  
          if (dir === 'right') {
            point3.add(movex, movey)
            if (point2.x >= max) break
          } else if (dir === 'left') {
            point3.subtract(movex, movey)
            if (point2.x <= min) break
          }
          ribbon.push({
            point1: new Point(point1.x, point1.y),
            point2: new Point(point2.x, point2.y),
            point3: point3,
            color: color,
            delay: delay,
            dir: dir,
            alpha: 0,
            phase: 0
          })
  
          point1.copy(point2)
          point2.copy(point3)
  
          delay += 4
        }
        this._ribbons.push(ribbon)
      },
  

      _drawRibbonSection: function(section) {
        if (section) {
          if (section.phase >= 1 && section.alpha <= 0) {
            return true 
          }
          if (section.delay <= 0) {
            section.phase += 0.02
            section.alpha = Math.sin(section.phase) * 1
            section.alpha = section.alpha <= 0 ? 0 : section.alpha
            section.alpha = section.alpha >= 1 ? 1 : section.alpha
  
            if (this._options.animateSections) {
              var mod = Math.sin(1 + section.phase * Math.PI / 2) * 0.1
  
              if (section.dir === 'right') {
                section.point1.add(mod, 0)
                section.point2.add(mod, 0)
                section.point3.add(mod, 0)
              } else {
                section.point1.subtract(mod, 0)
                section.point2.subtract(mod, 0)
                section.point3.subtract(mod, 0)
              }
              section.point1.add(0, mod)
              section.point2.add(0, mod)
              section.point3.add(0, mod)
            }
          } else {
            section.delay -= 0.5
          }
          var s = this._options.colorSaturation,
            l = this._options.colorBrightness,
            c =
  'hsla(' +
  section.color +
  ', ' +
  s +
  ', ' +
  l +
  ', ' +
  section.alpha +
  ' )';
  // color: rgb(2, 126, 213);

// Replace it with a specific blue hue value
var c = 'hsla(240, ' + s + ', ' + l + ', ' + section.alpha + ')'
  
          this._context.save()
  
          if (this._options.parallaxAmount !== 0) {
            this._context.translate(
              0,
              this._scroll * this._options.parallaxAmount
            )
          }
          this._context.beginPath()
          this._context.moveTo(section.point1.x, section.point1.y)
          this._context.lineTo(section.point2.x, section.point2.y)
          this._context.lineTo(section.point3.x, section.point3.y)
          this._context.fillStyle = c
          this._context.fill()
  
          if (this._options.strokeSize > 0) {
            this._context.lineWidth = this._options.strokeSize
            this._context.strokeStyle = c
            this._context.lineCap = 'round'
            this._context.stroke()
          }
          this._context.restore()
        }
        return false 
      },
  
      _onDraw: function() {
        for (var i = 0, t = this._ribbons.length; i < t; ++i) {
          if (!this._ribbons[i]) {
            this._ribbons.splice(i, 1)
          }
        }

        this._context.clearRect(0, 0, this._width, this._height)
  
        for (
          var a = 0;
          a < this._ribbons.length;
          ++a 
        ) {
          var ribbon = this._ribbons[a],
            numSections = ribbon.length,
            numDone = 0
  
          for (
            var b = 0;
            b < numSections;
            ++b 
          ) {
            if (this._drawRibbonSection(ribbon[b])) {
              numDone++ 
            }
          }
          if (numDone >= numSections) {
            this._ribbons[a] = null
          }
        }
        if (this._ribbons.length < this._options.ribbonCount) {
          this.addRibbon()
        }
        requestAnimationFrame(this._onDraw)
      },
  
      _onResize: function(e) {
        var screen = screenInfo(e)
        this._width = screen.width
        this._height = screen.height
  
        if (this._canvas) {
          this._canvas.width = this._width
          this._canvas.height = this._height
  
          if (this._context) {
            this._context.globalAlpha = this._options.colorAlpha
          }
        }
      },
      _onScroll: function(e) {
        var screen = screenInfo(e)
        this._scroll = screen.scrolly
      }
    }
    return Factory
  })
  
  new window.Ribbons({
    colorSaturation: '90%',
    colorBrightness: '50%',
    colorAlpha: 0.5,
    colorCycleSpeed: 5,
    verticalPosition: 'random',
    horizontalSpeed: 200,
    ribbonCount: 3,
    strokeSize: 0,
    parallaxAmount: -0.2,
    animateSections: true,
  })

  return (
    <div>
      <h1 className='textg'>PROJECTS</h1>
        <div className='text'>
          BRINGING ON BOARD THE  BEST SPECIALISTS ON THE MARKET, MINDWISE MAKES ANY VISIONARY CONCEPT A TANGIBLE REALITY.
        </div>

        <div className='flex'>

          <div className='papersP'>
            <img src={png1} alt="" />
            <div className='flex1'>
                <h2 className='text1PROJ'>Customs Business Process Reengineering</h2>
              <p className='text1pp'>
                On the project of customs business process reengineering, MindWise consultants developed recommendations to revise business processes and procedures at the customs border control points, including guidelines on border crossing procedures for taxpayers
              </p>
            </div>
            <div id='Index'>
              <a className='read' href="#popup">Read more...</a>
            </div>

            <div id='popup'>
                <div>
                  <a className='pop' href="#index">X</a>
                  <h2 className='text'>Customs Business Process Reengineering</h2>
                  <p>
                    On the project of customs business process reengineering, MindWise consultants developed recommendations to revise business processes and procedures at the customs border control points, including guidelines on border crossing procedures for taxpayers. The in-depth analyst and the recommendation of the team allowed for better experience of tax-payers at border control points, significant cost savings for the government, as well as helped start a number of projects that were aimed at implementing IT systems to automate various government functions at the border.
                  </p>
                </div>
            </div>

          </div>

          <div className='papersP'>
            <img src={png1} alt="" />
            <div className='flex2'>
              <h2 className='text2' >
                Assistance and Consultation on Computer Systems and Artificial Intelligence 
              </h2>
              <p className='text2pp'>
                In the framework of this project, our company completed two phases of work. In the first phase, titled 'Assistance and Consultation on Architectural Design of Computer Systems and Multi-Service Communication Software,' our primary goal was to enhance the architecture of software that ensures efficient and secure operation on multitasking systems. 
              </p>
            </div>
            <div id='Index1'>
              <a className='read1' href="#popup1">Read more...</a>
            </div>

            <div id='popup1'>
                <div>
                  <a className='pop1' href="#index1">X</a>
                  <h2 className='text'>
                    Assistance and Consultation on Computer Systems & Multi-services Communication Software Design and development of Machine Learning (ML) and Artificial Intelligence (AI) Based Analytical Models 
                  </h2>
                  <p>
                    In the framework of this project, our company completed two phases of work. In the first phase, titled 'Assistance and Consultation on Architectural Design of Computer Systems and Multi-Service Communication Software,' our primary goal was to enhance the architecture of software that ensures efficient and secure operation on multitasking systems. Our company successfully played the role of a consultant, analyzing user needs and issues, and providing recommendations for creating reliable and scalable architectural solutions. Additionally, we developed APIs and security measures to ensure seamless integration and data protection. We also conducted a review of the source code, identifying and rectifying inefficiencies and vulnerabilities. In the second phase, 'Design and Development of Analytical Models Based on Machine Learning (ML) and Artificial Intelligence (AI),' our company developed innovative ML and AI models. These models were configured to utilize user-generated data to enhance user satisfaction, retention, and predict various events. We designed and implemented a machine learning model for intrusion detection and prevention, as well as a user behavior analysis module. Special attention was given to developing algorithms for phishing and fraud detection, along with classification algorithms for SSL/TLS certificate validation.This project delivered modern and secure software, along with analytical models to optimize user experience and enhance system security. Our company completed the project on time, and our expertise in architectural design and machine learning provided significant advantages in a competitive environment. This project marked a crucial step in strengthening our technological infrastructure and ensuring the reliable protection of data and systems.
                  </p>
                </div>
            </div>
          </div>   

          <div className='papersP'>
            <div>
              <img src={png1} alt=""  />
            </div>
            <div className='flex3'>
              <h2 className='text3'>
                Computer Systems & Communication Equipment Software Design
              </h2>
              <p className='text3pp'>
                Our company successfully completed the design of computer systems and communication equipment software, including the design, implementation, operation, and maintenance of computer system software. These tasks involved analyzing user problems and their need for cost-effective solutions, as well as developing the necessary programs to implement such solutions. 
              </p>
            </div>

            <div id='Index2'>
              <a className='read2' href="#popup2">Read more...</a>
            </div>

            <div id='popup2'>
                <div>
                  <a className='pop2' href="#index2">X</a>
                  <h2 className='text'>
                    Computer Systems & Communication Equipment Software Design
                  </h2>
                  <p>
                    Our company successfully completed the design of computer systems and communication equipment software, including the design, implementation, operation, and maintenance of computer system software. These tasks involved analyzing user problems and their need for cost-effective solutions, as well as developing the necessary programs to implement such solutions. Additionally, we were involved in designing application software intended for use on smartphones and mobile devices.Our services encompassed: Providing support to our clients, including resolving end-user issues related to the services we provided.Managing the software design and development process.Conducting developer and customer testing.Handling software installation and integration.Providing support, maintenance, and ensuring service levels.Implementing updates and improvements.Delivering other related services.Escrowing the source code. Also, we successfully designed analytical models based on machine learning (ML) and artificial intelligence (AI). These models were tailored to user-generated data and served various purposes, such as evaluating user satisfaction, retaining users, and predicting different types of events.Our services in this area included:Developing models for comprehensive user data analysis.Designing ML/AI prediction models with binary or multi-class outcomes for user classification and event prediction.Creating natural language processing (NLP) models to analyze unstructured and semi-structured data, facilitating decision-making. This encompassed tasks like user sentiment analysis and user feedback analysis.Maintaining and regularly updating the models to incorporate new features and enhance their performance.
                  </p>
                </div>
            </div>
          </div>



          <div className='papersP'>
            <img src={png1} alt="" />
            <div className='flex4'>
              <h2 className='text4'>
                Mobile Tax Implementation Concept
              </h2>
              <p className='text4pp'>
                Using established benchmarks and the best practices on the market, the team prepared areport on m-Tax implementation, including global best practices, their scope, institutionalarrangements, technology, data management, and implementation modalities. The comprehensive report, its gap analysis and action plan with clear sequencing and costing of each of the actions,allows the government authorities to start the implementation of comprehensive m-Tax IT systems.
              </p>
            </div>          
          </div>

          <div className='papersP'>
            <img src={png1} alt="" />
            <div className='flex5'>
              <h2 className='text5'>
                Tax & Customs Risk Management Systems
              </h2>
              <p className='text5p'>
              Mindwise LLC specializes in the application of AI and ML technologies to enhance revenue administration, including both tax and customs domains. Our core focus lies in the development of intelligent, context-aware risk management systems that support authorities in identifying anomalies, assessing risk levels, and improving audit targeting through data-driven insights.
              Rather than offering generic, off-the-shelf products, we emphasize modular and adaptable solutions that reflect the unique operational and regulatory environments of each client. 
              One of our key innovations is the integration of relational data, such as taxpayer transaction networks, to enhance fraud detection. This approach allows for the effective identification of risk even among newly established entities and has proven to outperform traditional rule-based systems when benchmarked using lift scores in top-risk segments.              </p>
            </div>
          </div>


      </div>
        <footer>
          <p>Copyright © 2023 Mindwise. All rights reserved.</p>
        </footer>
      </div>
  );
}

export default Projects;

